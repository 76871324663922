import React, { useState } from "react"
import LazyLoad from 'react-lazy-load';

// Import SVG
import icon_search_SVG1 from '@images/kaigai-fx/icon_search1.svg';

export function WordSearch () {
  // 検索結果ページのURL
  let setUri = `/kaigai-fx/search-results/`
  
  // 入力情報の取得
  const [text, setText] = useState("");
  const [addText, setAddText] = useState("");
  const onClickAddText = () => {
    setAddText(text);
    setText("");
  }

  // inputでEnterが押された際のページ遷移 ※window.location以外での方法検討
  const handleSubmit = (event) => {
    window.location.href = setUri + "#" + addText;
    event.preventDefault();
  };

  const searchContentPC = <>
    <div className="relative w-full max-w-md py-[5px] mx-auto rounded-md hidden md:block md:flex">
      <form className="relative w-full" noValidate autoComplete="off" onSubmit={handleSubmit}>
        
        <button className="absolute inset-y-0 left-0 flex items-center pl-[16px] pr-[12px]" onClick={onClickAddText} aria-label="search button">  
          <a className="" href={setUri + "#" + addText} aria-label="search page">
            <LazyLoad className='' offset={300}>
              <img className="w-[90%]" alt="search icon" src={icon_search_SVG1}/>
            </LazyLoad>
          </a>
        </button>
        <input type="text" className="w-full py-3 pl-[56px] pr-4 text-sm text-gray-700 bg-white border rounded-md 
        focus:border-[#36A7C3] focus:outline-none focus:ring focus:ring-[#36A7C3] focus:ring-opacity-40" 
        placeholder="ワード検索" value={text} onChange={(event) => setText(event.target.value)}/>
      </form>
    </div>
  </>;
 
  const searchContentMB = <>
    <div id="search" className="relative w-full mx-auto bg-[#CCCCCC] p-6 mb-10 md:hidden">
      <form className="relative w-full" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <button className="absolute inset-y-0 left-0 flex items-center pl-[16px] pr-[12px]" onClick={onClickAddText} aria-label="search button">
          <a className="" href={setUri + "#" + addText} aria-label="search page">
            <LazyLoad className='' offset={300}>
              <img className="w-[90%]" alt="search icon" src={icon_search_SVG1}/>
            </LazyLoad>
          </a>
        </button>
        <input className="w-full py-3 pl-[52px] pr-4 border-gray-700 text-base text-gray-700 bg-white md:border border-none rounded-md focus:border-[#36A7C3] focus:outline-none focus:ring focus:ring-[#36A7C3] focus:ring-opacity-40" 
        type="text" placeholder="ワード検索" value={text} onChange={(event) => setText(event.target.value)}/>
      </form>
    </div>
  </>;

  return { PC:searchContentPC, MB:searchContentMB }
}
