import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import LazyLoad from 'react-lazy-load';
import { ArchiveVisualComponent } from "@components/kaigai-fx/archiveVisualComponent.js";

// Import SVG
import icon_post_SVG1 from '@images/kaigai-fx/post_icon1.svg';

export function RelatedContents ( categoryData, tagData, slugArray, data, num ) {
  
  const indexUrl = '/kaigai-fx/';
  const querydata = useStaticQuery(graphql`
    query relateddata {
      allWpPostData: allWpKaigaiFx(sort: {fields: modified, order: DESC}) {
        edges {
          node {
            title
            slug
            excerpt
            date(formatString: "YYYY/MM/DD")
            modified(formatString: "YYYY/MM/DD")
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      placeholder: BLURRED
                      layout: CONSTRAINED
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
            fxCategories {
              nodes {
                name
                slug
                count
                description
                wpParent {
                  node {
                    slug
                    name
                  }
                }
              }
            }
            fxTags {
              nodes {
                name
                slug
                wpParent {
                  node {
                    slug
                    name
                  }
                }
              }
            }
            acfSettings {
              eyeCatchingText1
              eyeCatchingText2
            }
          }
        }
      }
    }
  `);
  let setArray = [];
  const slugSet = new Set(slugArray);
  querydata.allWpPostData.edges.forEach(result => {
    if (result.node.fxTags.nodes.some(tag => tag.slug === 'display-on-top') || slugSet.has(result.node.slug)) {
      return; // 早期リターンで不要な記事をスキップ
    }
    const cCount = result.node.fxCategories.nodes.reduce((acc, item) => acc + (item.slug === categoryData.slug ? 1 : 0), 0);
    const tCount = result.node.fxTags.nodes.reduce((acc, item) => acc + (tagData.some(tag => tag.slug === item.slug) ? 1 : 0), 0);
    if (cCount === 0 && tCount === 0) return; // カテゴリもタグも一致しない場合はスキップ
    const score = cCount * 3 + tCount; // スコア計算
    result.node.score = score;
    setArray.push(result);
  });
  setArray.sort((a, b) => b.node.score - a.node.score); // スコア順に並び替え
  if (!num) num = 10; // num指定ない場合は10記事
  setArray = setArray.slice(0, num);

  // サムネイル画像設定
  const thumData = ArchiveVisualComponent(setArray, 'related', data);

  return <>
    {setArray.map((result, index) =>
      <a href={`${indexUrl}${result.node.slug}/`} className={`border-b-[1px] border-[#707070] w-[48%] block mb-6 md:w-full ${result.node.featuredImage? 'Campaign': 'Campaign2'}`} style={{height: '100%'}}>
        {/* 画像部分 */}
          {/*
        <div className="w-full" style={{height: '55%'}}>
          {result.node.featuredImage?
            <GatsbyImage
              image={getImage(result.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData)} alt={result.node.title} 
              className='block w-full aspect-[2/1] rounded-blog overflow-hidden'>
            </GatsbyImage>
            : 
            <div className='relative overflow-hidden rounded-blog aspect-[2/1]'>
              <div className='absolute z-10 h-full w-full flex justify-center items-center'>
                <p className={`relative ${thumData[index].textColor} ${thumData[index].fontSize.mb} ${thumData[index].fontSize.pc} ${thumData[index].fontSize.max} font-[900] text-center`} style={{top:`${thumData[index].position}px`}}>
                  {thumData[index].text2 !== '' && thumData[index].text2 !== undefined?
                    <p className='relative top-[6px] md:top-[6px] leading-[1.4]'>{thumData[index].text1}<br/>{thumData[index].text2}</p>
                    :
                    <>{thumData[index].text1}</>            
                  }
                </p>
              </div>
              <LazyLoad className='' offset={300}>
                <img className="mb-4 h-full w-full object-cover" alt={thumData[index].alt} src={thumData[index].img}/>
              </LazyLoad>
            </div>
          }
        </div> */}
        {/* タイトル部分 */}
        <p className="CampP bg-white w-full text-left mt-[6px] mb-[10px] md:text-xs text-[14px] font-bold" style={{ height: '20%' }}>{result.node.title}</p>
         {/* <p className="text-[12px] my-1 flex justify-end">
         <LazyLoad className='flex' width={18.72} height={18.72}offset={300}> 
            <img className="px-[3px]" width="18.72" height="18.72" alt="icon1" src={icon_post_SVG1}/>*/}
          {/* </LazyLoad> 
          <span className="px-1 text-[10px]">{result.node.modified}</span>
        </p>*/}
      </a>
     )}
  </>
}