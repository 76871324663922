export function ArchiveVisualComponent (name, form, archiveVisualData) {
	
  const data = archiveVisualData.node.smartCustomFields;

  // 画像文字設定
  // tailwind.config.jsに使用するサイズの登録が必要
  const setTextArray = [
    {setForm: 'mainVisual',
      setData: [
        {count: 6, pc: '6vw', mb: '8vw', max: '64px'}, // 6文字以下
        {count: 12, pc: '4vw', mb: '7vw', max: '52px'}, // 12文字以下
        {count: 16, pc: '4vw', mb: '6vw', max: '40px'}, // 16文字以下
        {count: 99, pc: '3vw', mb: '5vw', max: '32px'}, // 16文字より多い
      ]
    },
    {setForm: 'thumbnail',
      setData: [
        {count: 6, pc: '4vw', mb: '8vw', max: '28px'}, // 6文字以下
        {count: 12, pc: '1.6vw', mb: '7vw', max: '20px'}, // 12文字以下
        {count: 16, pc: '1.4vw', mb: '6vw', max: '16px'}, // 16文字以下
        {count: 99, pc: '1vw', mb: '5vw', max: '14px'}, // 16文字より多い
      ]
    },
    {setForm: 'related',
      setData: [
        {count: 6, pc: '4vw', mb: '4vw', max: '28px'}, // 6文字以下
        {count: 12, pc: '1.6vw', mb: '3.5vw', max: '20px'}, // 12文字以下
        {count: 16, pc: '1.4vw', mb: '3vw', max: '16px'}, // 16文字以下
        {count: 99, pc: '1vw', mb: '2.5vw', max: '14px'}, // 16文字より多い
      ]
    },
    {setForm: 'eyecatch',
      setData: [
        {count: 6, pc: '6vw', mb: '8vw', max: '64px'}, // 6文字以下
        {count: 12, pc: '4vw', mb: '7vw', max: '52px'}, // 12文字以下
        {count: 16, pc: '4vw', mb: '6vw', max: '40px'}, // 16文字以下
        {count: 99, pc: '3vw', mb: '5vw', max: '32px'}, // 16文字より多い
      ]
    },
  ]

  // function:文字数からテキストサイズを設定
  function countCharacters(text1, text2, form) {
    const textArray = [text1, text2];
    let maxCount = 0;
    textArray.forEach(text => {
      if (text !== null) {
        let counts = 0;
        for (let char of text) { // アルファベットは2/3文字,それ以外は1文字として計算
          counts += (char >= 'a' && char <= 'z') || (char >= 'A' && char <= 'Z') ? 2/3 : 1;
        }
        counts = Math.ceil(counts); // 小数点第一位を切り上げ
        if (counts > maxCount) {
          maxCount = counts; // 大きい方の文字カウント取得
        }
      }
    });
    const setText = setTextArray.find(item => item.setForm === form);
    if (!setText) return null; // formに対応する設定がない場合はnullを返す
    for (const item of setText.setData) {
      if (maxCount <= item.count) {
        return {pc: `md:text-[${item.pc}]`, mb: `text-[${item.mb}]`, max: `xl:text-[${item.max}]`};
      }
    }
    return null; // 条件に一致するサイズ設定がない場合はnullを返す
  }

  // function:必要データを生成する関数 
  function setArrayData1(setArray, setText1, setText2) {
    const resultArray = {
      text1: setText1, 
      text2: setText2, 
      position: setArray.text_position, // テキストポジション(上下)
      textColor: `text-[${setArray.font_color}]`, // フォントカラー
      fontSize: countCharacters(setText1, setText2, form), 
      alt: name, 
      img: setArray.image,
      ogpimg: setArray.ogpimage
    };
    return resultArray
  }

  // function:必要データを生成する関数 
  function setArrayData2(data) {
    let setArray = [];
    name.forEach((result) => {
      let categName = result.node.fxCategories.nodes.filter(item => item.wpParent !== null)[0].name;
      let setData = data.filter(item => item.broker_name === categName)[0];
      if (setData === undefined){ // アーカイブ画像未設定の場合
        setData = data.filter(item => item.broker_name === 'デフォルト画像')[0]; // nameフィルタ
      }
      setArray.push({textData: result.node.acfSettings, imageData: setData})
    })
    let postArray = [];
    setArray.forEach((result) => {
      if (result.textData !== null){
        let setPosition = result.imageData.text_position; // テキストポジション（上下）
        let setTextColor = `text-[${result.imageData.font_color}]`; // フォントカラー
        let setPixel = countCharacters(result.textData.eyeCatchingText1, result.textData.eyeCatchingText2, form)
        let postHtml = {
          text1: result.textData.eyeCatchingText1, 
          text2: result.textData.eyeCatchingText2, 
          position: setPosition, 
          textColor: setTextColor, 
          fontSize: setPixel, 
          alt: result.imageData.broker_name, 
          img: result.imageData.image,
          ogpimg: result.imageData.ogpimage
        };
        postArray.push(postHtml)
      } else {
        postArray.push('')
      }
    })
    return postArray
  }

  // データ取得
  let dataArray, setArray;
  switch (form) {
    case 'mainVisual':
      setArray = data.find(item => item.broker_name === name);
      if (!setArray) {
        setArray = data.find(item => item.broker_name === 'デフォルト画像');
      }
      dataArray = setArrayData1(setArray, setArray.archive_text1, setArray.archive_text2);
      break;
    case 'eyecatch':
      setArray = data.find(item => item.broker_name === name.categoryName);
      if (!setArray) {
        setArray = data.find(item => item.broker_name === 'デフォルト画像');
      }
      dataArray = setArrayData1(setArray, name.textData.eyeCatchingText1, name.textData.eyeCatchingText2);
      break;
    case 'thumbnail':
    case 'related':
      dataArray = setArrayData2(data);
      break;
  }

  return dataArray;
}