import React,{ useEffect,useCallback,useState }  from 'react';
import { Scrollbars } from 'rc-scrollbars'
import { useStaticQuery, graphql } from "gatsby";
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import SVG
import icon_search_SVG2 from '@images/kaigai-fx/icon_search2.svg';

export function SearchCategList () {

  const indexUrl = '/kaigai-fx/';

  // 全カテゴリ情報取得
  const data = useStaticQuery(graphql`
  query AllCategoryData {
    post: allWpFxCategory {
      edges {
        node {
          name
          slug
          count
          wpParent {
            node {
              name
              slug
              count
            }
          }
        }
      }
    }
  }
  `)

  let master = data.post.edges.filter(item => item.node.count !== null); // 記事数が0のカテゴリを削除
  let mainArray = master.filter(item => item.node.wpParent === null && item.node.count !== 0); // メインカテゴリ取得
  mainArray = mainArray.sort((a, b) => b.node.count - a.node.count); // メインカテゴリを登録記事数順位並び替え
  mainArray.forEach((result) => { // メインカテゴリ＞サブカテゴリの構造の配列を作成
    let setItem = master.filter(item => item.node.wpParent?.node.slug === result.node.slug);
    result.node.subCategory = setItem;
  })
  mainArray = mainArray.filter(item => item.node.subCategory.length !== 0); // サブカテゴリの登録数が0のメインカテゴリを削除

  // 出力するリストの設定
  const arrayList = <>
    {mainArray.map((result) =>
      <div className="mb-2">
        <div className="">
          <a className="block pl-2 px-3 rounded-sm text-[#36A7C3] text-base font-semibold hover:bg-gray-100 focus:ring-2 focus:ring-[#36A7C3]" 
             href={`${indexUrl}categories/${result.node.slug}/`}>{result.node.name} ( {result.node.count} )
          </a>
        </div>
        <div className="">
          {result.node.subCategory.map((item) =>
            <div classsName="">
              <a className="block rounded-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-[#36A7C3] font-semibold" 
                 href={`${indexUrl}categories/${item.node.slug}/`}>・ {item.node.name} ( {item.node.count} )
              </a>
            </div>
          )}
        </div>  
    </div>
    )}
  </>
  
  // モーダル用設定
  const[isModalOpen,setIsModalOpen]=useState(false)
  const closeModal= useCallback(() =>{
    setIsModalOpen(false)
    document.removeEventListener('click',closeModal)
  },[])

  useEffect(()=>{
    return ()=>{
      document.removeEventListener('click',closeModal)
    }
  },[closeModal])

  function openModal(event){
    setIsModalOpen(true)
    document.addEventListener('click',closeModal)
    event.stopPropagation()
  }

  const searchList = () => {
    return  <>
      <div className="">
        <button onClick={(event)=>{openModal(event)}} 
        className="relative mb-[13px] border py-3 pr-5 pl-[12px] rounded-md text-sm hidden md:block md:flex w-full h-[46px]">
          <span className="pr-[12px] relative top-[2px] w-[20%]"><LazyLoadImage alt="search icon" src={icon_search_SVG2}/></span>
          <span className="hover:text-gray-400 text-left w-[80%]">カテゴリー検索</span>
          {isModalOpen? <Modal onClick={(event)=>{closeModal(event)}}/> :""}
        </button>
      </div>
    </>
  }

  // 子コンポーネント（モーダル）
  function Modal(props){
    return(
      <button id="modal" className="modal !z-10" onClick={(event)=>{event.stopPropagation()}}>
        <button onClick={props.onClick} className="absolute top-0 left-0 mb-[20px] py-3 pr-5 pl-[12px] text-sm hidden md:block md:flex w-full h-[46px]">
          <span className="pr-[12px] relative top-[2px] w-[20%]"><LazyLoadImage alt="search icon" src={icon_search_SVG2}/></span>
          <span className="bg-white hover:text-gray-400 text-left w-[80%]">閉じる</span>
        </button>
        <div className="absolute left-[-1px] text-left">
          <div className="md:block text-xs rounded-md border p-3 bg-white absolute w-[228px] bottom-[-420px] leading-loose z-50 shadow-xl">
            <p className=" rounded-sm mb-3 font-semibold text-xs px-2 py-2 bg-[#36A7C3] text-white">カテゴリーを選択</p>
            <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={310} >
              {arrayList}
            </Scrollbars>
          </div>
        </div>
      </button>
    )
  }

  const fixFooterItem = 
  <>
    <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={350} >
      {arrayList}
    </Scrollbars>
  </>

  return { Main:searchList(), Sub:fixFooterItem }  
}