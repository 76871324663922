import React, { useState, useEffect, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Slider = ({imageArray}) => {

  // Slider制御設定
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const touchStartX = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex =>
        prevIndex === imageArray.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // ※画像切り替わりの時間を指定
    return () => clearInterval(interval);
  }, [imageArray]);

  // 画像タップ操作切り替わり
  const handleTouchStart = event => {
    touchStartX.current = event.touches[0].clientX;
  };
  const handleTouchEnd = event => {
    const touchEndX = event.changedTouches[0].clientX;
    const touchDiff = touchEndX - touchStartX.current;
    if (touchDiff > 100) {
      // 左にスワイプ
      setCurrentIndex(prevIndex =>
        prevIndex === 0 ? imageArray.length - 1 : prevIndex - 1
      );
    } else if (touchDiff < -100) {
      // 右にスワイプ
      setCurrentIndex(prevIndex =>
        prevIndex === imageArray.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  // 画像切り替わり時のフェードイン＆アウト
  useEffect(() => {
    if (sliderRef.current) {
      const slides = sliderRef.current.children;
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.opacity = i === currentIndex ? 1 : 0;
        slides[i].style.position = i === currentIndex ? 'static' : 'absolute';
        slides[i].style.transition = 'opacity 1.5s'; // ※秒数調整
      }
    }
  }, [currentIndex]);

  return (
    <div
      className="slider-container"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className="slider" ref={sliderRef}>
        
        {imageArray.map((result, index) => (
          <div
            key={index}
            className="slide"
            style={{
              opacity: index === currentIndex ? 1 : 0,
              position: index === currentIndex ? 'static' : 'absolute',
              zIndex: index === currentIndex ? 1 : 0,
            }}
          >
            <a className="" href={result.url} aria-label={`slider${index + 1}`} {...result.linktype !== '内部リンク' ? {target: '_blank', rel: 'noopener noreferrer'}: {}}>
							{result.text === null? <></>: <span className="hidden">{result.text}</span>}
              <GatsbyImage
                image={getImage(result.image?.localFile?.childImageSharp?.gatsbyImageData)}
                alt={result.title}
              />
						</a>        
          </div>
        ))}

      </div>

      {/* ドット部分 */}
      <div className="dots">
        {imageArray.map((_, index) => (
          <span
            key={index}
            className={index === currentIndex ? 'dot active' : 'dot'}
            onClick={() => setCurrentIndex(index)}
            onKeyPress={(e) => { if (e.key === 'Enter' || e.key === ' ') setCurrentIndex(index); }}
            tabIndex="0"
            role="button"
            aria-pressed={index === currentIndex}
            aria-label={`Go to slide ${index + 1}`}
          ></span>
        ))}
      </div>

    </div>
  );
};

export default Slider;
